import React, { useEffect, useState } from 'react'
import styles from './Container.module.css'
import ContainerTable from '../../Utills/Table/ContainerTable'
import Navbar from '../../Components/Navbar/Navbar'
import ContainerAddModal from '../../Utills/Modal/ContainerAddModal'
import axios from 'axios'
import MinusContainerModal from '../../Utills/Modal/MinusModal/MinusContainerModal'
import add_icon from '../../Assets/uttils/add_button.svg'

const Container = () => {
  const [isModal, setIsModal] = useState(false);
  const [minusModal, setMinusModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [containers, setContainers] = useState([]);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState('');
  const [status, setStatus] = useState('');
  const [shop, setShop] = useState('');

  const userInfo = JSON.parse(window.localStorage.getItem('userId'));

  const closeModal = () => {
    setIsModal(false);
    setMinusModal(false);
  }

  useEffect(() => {
    window.localStorage.setItem('side-id', 3);
    if (isLoading) {
      // axios.get('https://oldapi.turbodelivery.mn/container?user=' + userInfo._id)
      axios.get('https://oldapi.turbodelivery.mn/container?sort=-createdAt&user=' + userInfo._id)
        .then((e) => {
          setIsLoading(false);
          setContainers(e.data.data);
        })
        .catch((e) => alert(e));

      axios.get('https://oldapi.turbodelivery.mn/shop?sort=-createdAt&user=' + userInfo._id)
        .then((e) => {
          setIsLoading(false);
          setShop(e.data.data);
        })
        .catch((e) => alert(e));

      axios.get('https://oldapi.turbodelivery.mn/user')
        .then((e) => {
          setIsLoading(false);
          setUsers(e.data.data);
        })
        .catch((e) => alert(e));
    }
  }, [isLoading]);

  return (
    <>
      {!isModal ? <Navbar title={'Агуулах бараа'} /> : <></>}
      {isModal ? <div className='abs'><ContainerAddModal closeModal={closeModal} /></div> : <></>}
      {minusModal ? <div className='abs'><MinusContainerModal closeModal={closeModal} /></div> : <></>}
      {isLoading ? <div className="loader-container"><div className='loader'></div></div> : <></>}
      <div className={styles.container}>
        <div className='line-start'>
          <button onClick={() => setIsModal(true)} className='button'><img src={add_icon} className='icon' alt="" /> Нэмэх</button>
        </div>
        <ContainerTable containers={containers} filterStatus={status} user={user} users={users} shop={shop} />
      </div>
    </>
  )
}

export default Container
