import React, { useEffect, useState } from 'react'
import styles from './DriverHistory.module.css'
// import OrderTable from '../../Utills/Table/OrderTable';
import Navbar from '../../../Components/Navbar/Navbar';
import OrderAddModal from '../../../Utills/Modal/OrderAddModal';
import axios from 'axios';
import EditOrderModal from '../../../Utills/Modal/EditModal/EditOrderModal';
import DriverHistoryTable from '../../../Utills/Table/DriverHistoryTable';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';

const DriverHistory = () => {
    const [isModal, setIsModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editId, setEditId] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [users, setUsers] = useState([]);
    const [driver, setDriver] = useState('');
    const [startValue, setStartValue] = useState(dayjs(Date.now()));
    const [endValue, setEndValue] = useState(dayjs(Date.now()));
    const [isStartCalendar, setIsStartCalendar] = useState(false);
    const [isEndCalendar, setIsEndCalendar] = useState(false);
    const [finance, setFinance] = useState([]);



    const closeModal = () => {
        setIsModal(false);
        setEditModal(false);
    }

    useEffect(() => {
        window.localStorage.setItem('side-id', 9);

        if (isLoading) {
            axios.get('https://oldapi.turbodelivery.mn/finance/' + '?delivered=true')
                .then((e) => { setFinance(e.data.data); setIsLoading(false) })
                .catch((e) => alert(e));

            axios.get('https://oldapi.turbodelivery.mn/order?sort=-createdAt')
                .then((e) => { setOrders(e.data.data) })
                .catch((e) => console.log(e));

            axios.get('https://oldapi.turbodelivery.mn/user?sort=-createdAt&role=driver')
                .then((e) => { setDrivers(e.data.data); setIsLoading(false) })
                .catch((e) => console.log(e));


            axios.get('https://oldapi.turbodelivery.mn/user?role=user')
                .then((e) => {
                    setUsers(e.data.data);
                    setIsLoading(false);
                })
        }
    }, [isLoading])

    const edit = ({ id }) => {
        setEditId(id);
        setEditModal(true);
    }



    const calculateTotalPrice = () => {
        return finance
            .filter((e) => driver ? (e.driver === driver) : e)
            .filter((el) => el.delivered === true)
            .reduce(
                (itemTotal, item) =>
                    itemTotal +
                    ((
                        (dayjs(item.orderDate).isBefore(dayjs(startValue)) && dayjs(item.orderDate).isAfter(dayjs(endValue)))
                        || (dayjs(item.orderDate).isSame(endValue, 'date') && dayjs(item.orderDate).isSame(startValue, 'date'))
                    ) ? item.unitPrice : 0),

                0
            );
    };

    const calculateTotalDeposit = () => {
        return finance
            .filter((e) => driver ? (e.driver === driver) : e)
            .filter((el) => el.status === true)
            .reduce(
                (itemTotal, item) =>
                    itemTotal +
                    ((
                        (dayjs(item.orderDate).isBefore(dayjs(startValue)) && dayjs(item.orderDate).isAfter(dayjs(endValue)))
                        || (dayjs(item.orderDate).isSame(endValue, 'date') && dayjs(item.orderDate).isSame(startValue, 'date'))
                    ) ? item.deposit : 0),
                0
            );
    };

    const calculateTotalDelivered = () => {
        return finance
            .filter((e) => driver ? (e.driver === driver) : e)
            .filter((el) =>
                (dayjs(el.orderDate).isBefore(dayjs(startValue)) && dayjs(el.orderDate).isAfter(dayjs(endValue))
                    || (dayjs(el.orderDate).isSame(endValue, 'date') && dayjs(el.orderDate).isSame(startValue, 'date'))
                ) &&
                el.delivered).length * 6000;
    };


    return (
        <>
            {!isModal ? <Navbar title={'Жолоочийн түүх'} /> : <></>}
            {isModal ? <div className='abs'><OrderAddModal closeModal={closeModal} /></div> : <></>}
            {editModal ? <div className='abs'><EditOrderModal id={editId} closeModal={closeModal} /></div> : <></>}
            {isLoading ? <div className="loader-container"><div className='loader'></div></div> : <></>}
            <div className={styles.container}>
                <div className='line-start'>
                    {/* <button onClick={() => setIsModal(true)} className='button'><img src={add_icon} className='icon' alt="" /> Нэмэх</button>
                    <button className='button-white'>See Item</button>
                    <button className='button-white'>Print</button> */}
                    <div className='line-start'>
                        <div style={{ width: '20vw' }}>
                            {isEndCalendar ? (
                                <div className="calendar-div">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                                            <DemoItem>
                                                <p onClick={() => setIsEndCalendar(false)}>Hide</p>
                                                <DateCalendar value={endValue} onChange={(newValue) => { setEndValue(newValue); setIsEndCalendar(false) }} />
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                            ) : (
                                <label className='calendar-label' onClick={() => setIsEndCalendar(true)}>
                                    {/* End date: */}
                                    Эхлэх хугацаа:
                                    <p className='calendar'>{endValue.format('YYYY-MM-DD')}</p>
                                </label>
                            )}
                        </div>
                        <div style={{ width: '20vw' }}>
                            {isStartCalendar ? (
                                <div className="calendar-div">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                                            <DemoItem>
                                                <p onClick={() => setIsStartCalendar(false)}>Hide</p>
                                                <DateCalendar value={startValue} onChange={(newValue) => { setStartValue(newValue); setIsStartCalendar(false) }} />
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                            ) : (
                                <label className='calendar-label' onClick={() => setIsStartCalendar(true)}>
                                    Дуусах хугацаа:
                                    <p className='calendar'>{startValue.format('YYYY-MM-DD')}</p>
                                </label>
                            )}
                        </div>
                        <label>
                            Жолоочоор шүүх:
                            <select name="" id="" onChange={(e) => setDriver(e.target.value)}>
                                <option value=''>Бүгд</option>
                                {drivers.map((e) => (
                                    <option value={e._id}>{e.name}</option>
                                ))}
                            </select>
                        </label>
                    </div>
                </div>
                <br />
                <div className='line-three'>
                    <div className='dashboard-card'>
                        <p style={{ padding: '10px' }}>Нийт дүн</p>
                        <div className='divider-dashboard'></div>
                        <h3>{new Intl.NumberFormat('en-Us').format(calculateTotalPrice())}</h3>
                    </div>
                    <div className='dashboard-card'>
                        <p style={{ padding: '10px' }}>Хүргэлтийн дүн</p>
                        <div className='divider-dashboard'></div>
                        <h3>{new Intl.NumberFormat('en-Us').format(calculateTotalDelivered())}</h3>
                    </div>
                    <div className='dashboard-card'>
                        <p style={{ padding: '10px' }}>Татан авалтын дүн</p>
                        <div className='divider-dashboard'></div>
                        <h3>{new Intl.NumberFormat('en-Us').format(calculateTotalDeposit())}</h3>
                        {/* <h3>{new Intl.NumberFormat('en-US').format(calculateTotalPrice())}</h3> */}
                    </div>
                </div>
                <br />
                <br />
                <div>
                    <DriverHistoryTable orders={orders.filter((e) => driver ? e.driver === driver : e)
                        .filter((e) => (dayjs(e.createdAt).isAfter(endValue) && dayjs(e.createdAt).isBefore(startValue))
                            || (dayjs(e.createdAt).isSame(endValue, 'date') && dayjs(e.createdAt).isSame(startValue, 'date')))}
                        drivers={drivers} edit={edit} user={users} />
                </div>
            </div>
        </>
    )
}

export default DriverHistory
