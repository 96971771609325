import axios from 'axios';
import React, { useState } from 'react'

const DriverOrderStatusChangeModal = ({ id, closeModal, orderStatus, phone, address }) => {
    const [status, setStatus] = useState('');

    const changeStatus = () => {
        // alert(status);
        axios.put('https://oldapi.turbodelivery.mn/order/proto/driver/' + id, {
            status: status
        })
            .then(() => window.location.reload(false))
            .catch((e) => alert(e));
    }
    return (
        <div className='blur_container' style={{ position: 'absolute', width: '100vw', left: 0, top: 0, height: '100vh', zIndex: '20' }}>
            <div className='modal'>
                {/* <h4>{e.phone} - {e.address}</h4> */}
                <div className="two-line">
                    <h2>Төлөв өөрчлөх</h2>
                    <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                </div>
                <div className="two-line">
                    <p>{address} / {phone}</p>
                    <p style={{ color: 'green', fontWeight: '600' }}>{orderStatus}</p>
                </div>
                <select onChange={(e) => setStatus(e.target.value)}>
                    {/* 'Хүлээгдэж байна', 'Жолооч авсан', 'Хаягаар очсон', 'Хүргэсэн', 'Буцаагдсан' */}
                    {/* <option value="Хүлээгдэж байна">Хүлээгдэж байна</option> */}
                    <option value="Буцаагдсан">Буцаагдсан</option>
                    <option value="Жолооч авсан">Жолооч авсан</option>
                    <option value="Хаягаар очсон">Хаягаар очсон</option>
                    <option value="Хүлээж авсан">Хойшлуулсан</option>
                    <option value="Хүлээж авсан">Маргааш авах </option>
                    <option value="Хүргэсэн">Хүргэсэн</option>
                </select>
                <button onClick={changeStatus} className='button-white'>save</button>
            </div>
        </div>
    )
}

export default DriverOrderStatusChangeModal
