import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FaTrash } from "react-icons/fa";
import axios from 'axios';
import dayjs from 'dayjs';

const XlsxImportPage = ({ closeModal }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [createLoading, setCreateLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [shops, setShops] = useState([]);
    const [orders, setOrders] = useState([]);
    const [container, setContainer] = useState([]);
    const [areaContainers, setAreaContainers] = useState([]);
    const [areaContainer, setAreaContainer] = useState([]);
    const [loader, setLoader] = useState(false);
    const [xlsxFiles, setXlsxFiles] = useState([]);

    const userInfo = JSON.parse(window.localStorage.getItem('userId'));

    const calculateTotalDownloadBalance = ({ name }) => {
        return container
            .filter((e) => areaContainer === e.areacontainer)
            .filter((e) => e.canceled === false)
            .reduce((total, report) => {
                return (
                    total +
                    report.item
                        .filter((e) => e.name === name)
                        .filter((el) => el.status === 'Хүлээж авсан')
                        .filter((el) => el.switchsuccess === true)
                        .reduce(
                            (itemTotal, item) =>
                                itemTotal + item.staticUnit,
                            0
                        )
                );
            }, 0);
    };

    const calculateTotalOrdersBalance = ({ name }) => {
        return orders
            .filter((e) => areaContainer === e.container)
            .reduce((total, report) => {
                return total + report.order
                    .filter((e) => e.name === name)
                    .reduce((itemTotal, item) => itemTotal + item.unit, 0);
            }, 0);
    };

    const calculateTotalCancleled = ({ name }) => {
        return orders
            .filter((e) => areaContainer === e.container)
            .filter((e) => e.status === 'Буцаагдсан' || e.status === 'Хаягаар очсон')
            .reduce((total, report) => {
                return total + report.order
                    .filter((e) => e.name === name)
                    .reduce((itemTotal, item) => itemTotal +
                        (
                            (
                                (dayjs(report.orderDate).add(16, 'hours').isBefore(dayjs().add(8, 'hour')) ||
                                    dayjs(report.orderDate).add(16, 'hours').isSame(dayjs().add(8, 'hour'), 'date'))
                            ) ?
                                item.unit : 0), 0);
            }, 0);
    };

    const calculateTotalRemoved = ({ name }) => {
        return container
            .filter((e) => areaContainer === e.areacontainer)
            .reduce((total, report) => {
                return total + report
                    .item
                    .filter((el) => el.status === 'Зарлага')
                    .filter((e) => {
                        return e.name === name
                    })
                    .filter((el) => el.switchsuccess === true)
                    .reduce((itemTotal, item) => itemTotal +
                        (dayjs(report.date).isBefore(dayjs().add(8, 'hour')) ||
                            dayjs(report.date).isSame(dayjs().add(8, 'hour'), 'date')
                            ? item.staticUnit : 0), 0);
            }, 0);
    };

    const handleChange = (event, fileId, field) => {
        const { value } = event.target;
        const updatedFiles = xlsxFiles.map((file, index) => {
            if (index === fileId) {
                return { ...file, [field]: value };
            }
            return file;
        });
        setXlsxFiles(updatedFiles);
    };

    const handleRowChange = (e, fileId, rowIndex, field) => {
        const updatedInputs = xlsxFiles[fileId].inputs.map((row, index) => {
            if (index === rowIndex) {
                return { ...row, [field]: e.target.value };
            }
            return row;
        });
        const updatedFiles = xlsxFiles.map((file, index) => {
            if (index === fileId) {
                return { ...file, inputs: updatedInputs };
            }
            return file;
        });
        setXlsxFiles(updatedFiles);
    };

    const handleDeleteInput = (fileId, rowIndex) => {
        const updatedInputs = xlsxFiles[fileId].inputs.filter((_, index) => index !== rowIndex);
        const updatedFiles = xlsxFiles.map((file, index) => {
            if (index === fileId) {
                return { ...file, inputs: updatedInputs };
            }
            return file;
        });
        setXlsxFiles(updatedFiles);
    };

    const handleAddInput = (fileId) => {
        const newInput = { name: "", unit: 0, price: 0, unitPrice: 0, totalBalance: 0 };
        const updatedInputs = [...xlsxFiles[fileId].inputs, newInput];
        const updatedFiles = xlsxFiles.map((file, index) => {
            if (index === fileId) {
                return { ...file, inputs: updatedInputs };
            }
            return file;
        });
        setXlsxFiles(updatedFiles);
    };

    const handleCheckboxChange = (fileId, field) => {
        const updatedFiles = xlsxFiles.map((file, index) => {
            if (index === fileId) {
                return { ...file, [field]: !file[field] };
            }
            return file;
        });
        setXlsxFiles(updatedFiles);
    };

    const handleFileUpload = (e) => {
        const files = e.target.files;
        const newFiles = [];

        for (const file of files) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const workbook = XLSX.read(event.target.result, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];

                const sheetData = XLSX.utils.sheet_to_json(sheet);

                const groupedData = sheetData.reduce((acc, item) => {
                    const id = item['№'];
                    if (!acc[id]) {
                        acc[id] = [];
                    }
                    acc[id].push(item);
                    return acc;
                }, {});

                const transformedData = Object.values(groupedData).map(orderGroup => {
                    const firstOrder = orderGroup[0];
                    return {
                        price: orderGroup.reduce((total, order) => total + (order['бараа дүн'] || 0), 0),
                        orderDate: dayjs().add(8, 'hour'),
                        phone: firstOrder['утас'] || '',
                        address: firstOrder['хаяг'] || '',
                        discount: firstOrder['хөнгөлөлт'] || '',
                        inputs: orderGroup.map(order => ({
                            name: order['барааны нэр'] || '',
                            unit: order['бараа тоо'] || 0,
                            price: order['бараа дүн'] || 0,
                            unitPrice: order['бараа дүн'] || 0,
                            totalBalance: order['бараа дүн'] || 0
                        })),
                        shop: firstOrder['Дэлгүүр'] || '',
                    };
                });

                newFiles.push(...transformedData);

                setXlsxFiles(prevFiles => [...prevFiles, ...newFiles]);
            };

            reader.readAsBinaryString(file);
        }
    };

    const handleAddOrder = (fileId) => {
        if (!createLoading) {
            setCreateLoading(true);
            addOrder(fileId);
        }
    };

    const addOrder = (fileId) => {
        setLoader(true);
        const { address, phone, shop, discount, down, countryside, areaContainer, isPay, inputs, totalPrice } = xlsxFiles[fileId];
        axios.post('https://oldapi.turbodelivery.mn/order', {
            address,
            phone,
            shop,
            discount,
            download: down,
            countryside,
            container: areaContainer,
            isBill: isPay,
            order: inputs,
            user: userInfo._id,
            price: totalPrice,
            orderDate: dayjs().add(8, 'hour')
        }).then(() => window.location.reload(false))
            .catch((e) => {
                alert(e);
                setCreateLoading(false);
            });
    };

    useEffect(() => {
        if (isLoading) {
            axios.get('https://oldapi.turbodelivery.mn/post?user=' + userInfo._id)
                .then((e) => {
                    setItems(e.data);
                    setIsLoading(false);
                })
                .catch((e) => console.log(e));

            axios.get('https://oldapi.turbodelivery.mn/container?user=' + userInfo._id)
                .then((e) => {
                    setContainer(e.data.data);
                    setIsLoading(false);
                })
                .catch((e) => console.log(e));

            axios.get('https://oldapi.turbodelivery.mn/shop?user=' + userInfo._id)
                .then((e) => {
                    setShops(e.data.data);
                })
                .catch((e) => console.log(e));

            axios.get('https://oldapi.turbodelivery.mn/order?user=' + userInfo._id)
                .then((e) => {
                    setOrders(e.data.data);
                })
                .catch((e) => console.log(e));

            axios.get('https://oldapi.turbodelivery.mn/areacontainer')
                .then((e) => {
                    setAreaContainers(e.data.data);
                })
                .catch((e) => console.log(e));
        }
    }, [isLoading]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: '500vh', overflow: 'scroll' }}>
            sdsads
            <input type="file" onChange={handleFileUpload} />
            {isLoading || createLoading || loader ? <div className="loader"></div> :
                xlsxFiles.map((xlsx, index) => (
                    <div className='modal' style={{ width: '65vw', marginTop: '50px' }} key={index}>
                        <div className='two-line' style={{ width: '65vw' }}>
                            <h2>Захиалга нэмэх {index}</h2>
                            <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                        </div>
                        <br />
                        <label>
                            Харилцагчийн хаяг:
                            <textarea name="address" value={xlsx.address} onChange={(e) => handleChange(e, index, 'address')} cols="40" rows="5"></textarea>
                        </label>
                        <label>
                            Харилцагчийн утас:
                            <input type="text" name="phone" value={xlsx.phone} onChange={(e) => handleChange(e, index, 'phone')} />
                        </label>
                        <label>
                            Дэлгүүр сонгох:
                            <select name="shop" value={xlsx.shop} onChange={(e) => handleChange(e, index, 'shop')}>
                                <option value=''>Сонгох</option>
                                {shops.map((e) => (
                                    <option key={e.id} value={e.name}>{e.name}</option>
                                ))}
                            </select>
                        </label>
                        <label>
                            Агуулах сонгох:
                            <select name="areaContainer" value={xlsx.areaContainer} onChange={(e) => handleChange(e, index, 'areaContainer')}>
                                <option value=''>Сонгох</option>
                                {areaContainers.map((e) => (
                                    <option key={e.id} value={e._id}>{e.name}</option>
                                ))}
                            </select>
                        </label>
                        <label>
                            Хөнгөлөх дүн:
                            <input name="discount" value={xlsx.discount} onChange={(e) => handleChange(e, index, 'discount')} type="text" />
                        </label>
                        <div className='checkbox_container'>
                            <FormControlLabel onChange={() => handleCheckboxChange(index, 'isPay')} control={<Checkbox checked={xlsx.isPay} />} label="Төлбөр төлсөн эсэх" />
                            <FormControlLabel onChange={() => handleCheckboxChange(index, 'down')} control={<Checkbox checked={xlsx.down} />} label="Татан авалттай эсэх" />
                            <FormControlLabel onChange={() => handleCheckboxChange(index, 'countryside')} control={<Checkbox checked={xlsx.countryside} />} label="Хөдөө орон нутаг" />
                        </div>
                        <button onClick={() => handleAddInput(index)} className='button-white'>Мөр Нэмэх</button>
                        <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
                            <Table sx={{ width: '65vw', maxWidth: '65vw' }} aria-label="simple table">
                                <p>Total Price: {xlsx.totalPrice}</p>
                                <TableHead>
                                    <TableRow style={{ background: '#2c9961', color: '#fff' }}>
                                        <TableCell style={{ color: '#fff', fontSize: 16 }}>Бараа</TableCell>
                                        <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Нэгж үнэ</TableCell>
                                        <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Үлдэгдэл</TableCell>
                                        <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Тоо</TableCell>
                                        <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Нийт дүн</TableCell>
                                        <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Үйлдэл</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {xlsx.inputs.map((row, rowIndex) => (
                                        <TableRow
                                            className='hover'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            key={rowIndex}
                                        >
                                            <TableCell component="th" scope="row">
                                                <select name="name" value={row.name} onChange={(e) => handleRowChange(e, index, rowIndex, 'name')}>
                                                    <option value=''>Сонгох</option>
                                                    {items.map((e) => (
                                                        <option key={e.id} value={e.title}>{e.title}</option>
                                                    ))}
                                                </select>
                                            </TableCell>
                                            <TableCell align="center">
                                                {items.map((e) => (
                                                    e.title && row.name === e.title ? (
                                                        <p key={e.id}>{e.price}</p>
                                                    ) : null
                                                ))}
                                            </TableCell>
                                            <TableCell align="center">
                                                {items.map((e) => (
                                                    e.title && row.name === e.title ? (
                                                        <p key={e.id}>
                                                            {
                                                                calculateTotalDownloadBalance({ name: e.title }) -
                                                                calculateTotalOrdersBalance({ name: e.title }) +
                                                                calculateTotalCancleled({ name: e.title }) -
                                                                calculateTotalRemoved({ name: e.title })
                                                            }
                                                        </p>
                                                    ) : null
                                                ))}
                                            </TableCell>
                                            <TableCell align="center">
                                                <input type="number" name="unit" value={row.unit} onChange={(e) => handleRowChange(e, index, rowIndex, 'unit')} />
                                            </TableCell>
                                            <TableCell align="center">
                                                {items.map((e) => (
                                                    e.title && row.name === e.title ? (
                                                        <p key={e.id}>
                                                            {parseInt(e.price) * (row.unit || 1)}
                                                        </p>
                                                    ) : null
                                                ))}
                                            </TableCell>
                                            <TableCell align="center">
                                                <FaTrash onClick={() => handleDeleteInput(index, rowIndex)} size={30} color='red' />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className='button_container'>
                            {createLoading === false &&
                                <button onClick={() => handleAddOrder(index)} className='button-white' style={{ color: '#2c9961' }}>
                                    Хадгалах
                                </button>
                            }
                            <button onClick={closeModal} className='button-white' style={{ color: 'red' }}>Хаах</button>
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default XlsxImportPage;
