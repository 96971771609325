import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Navbar from '../../../Components/Navbar/Navbar'
import axios from 'axios';
import styles from './ShopReport.module.css'
import ReportShopTable from '../../../Utills/Table/ShopReportTable';

const clearDuplicates = (arr) => {
  const uniqueDates = {};
  const result = [];

  arr.forEach((item) => {
    const date = item.savedDate.split(' ')[0]; // Он, сар буюу YYYY-MM-DD форматтай болгоно

    if (!uniqueDates[date]) {
      result.push(item);
      uniqueDates[date] = true;
    }
  });

  return result;
};

const ShopReport = ({ orders }) => {
  const [startValue, setStartValue] = useState(dayjs(dayjs()));
  const [endValue, setEndValue] = useState(dayjs(dayjs()));
  const [isStartCalendar, setIsStartCalendar] = useState(false);
  const [isEndCalendar, setIsEndCalendar] = useState(false);
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [orders, setOrders] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [depositsuccess, setDepositsSuccess] = useState([]);

  const userInfo = JSON.parse(window.localStorage.getItem('userId'));

  useEffect(() => {
    window.localStorage.setItem('side-id', 4);

    if (isLoading) {
      // axios.get('https://oldapi.turbodelivery.mn/order?user=' + userInfo._id)
      //   .then((e) => { setOrders(e.data.data) })
      //   .catch((e) => alert(e));

      axios.get('https://oldapi.turbodelivery.mn/purchasedate?sort=-createdAt')
        .then((e) => {
          const uniqueReports = clearDuplicates(e.data.data); // Давхардсан мэдээллийг арилгах
          setReports(uniqueReports);

          setIsLoading(false);
        })
        .catch((e) => alert(e));

      axios.get('https://oldapi.turbodelivery.mn/deposit?user=' + userInfo._id)
        .then((e) => {
          setDeposits(e.data.data);
          setIsLoading(false);
        })

      axios.get('https://oldapi.turbodelivery.mn/depositsuccess?sort=-createdAt&user=' + userInfo._id)
        .then((e) => {
          setDepositsSuccess(e.data.data);
          setIsLoading(false);
        })
    };
  }, [isLoading])

  // new dayjs(Date.now()) > startValue ? alert('date') : alert('false');
  return (
    <>
      <Navbar title={'Агуулахын тайлан'} />
      {isLoading ? <div className="loader-container"><div className='loader'></div></div> : <></>}
      <div className={styles.container}>
        <div className='flex-space-between'>
          <div className='space-between'>
            <div style={{ width: '20vw', zIndex: 1 }}>
              {isEndCalendar ? (
                <div className='calendar-div'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                      <DemoItem>
                        <p onClick={() => setIsEndCalendar(false)}>Hide</p>
                        <DateCalendar value={endValue} onChange={(newValue) => { setEndValue(newValue); setIsEndCalendar(false) }} />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              ) : (
                <label className='calendar-label' onClick={() => setIsEndCalendar(true)}>
                  {/* End date: */}
                  Эхлэх хугацаа:
                  <p className='calendar'>{endValue.format('YYYY-MM-DD')}</p>
                </label>
              )}
            </div>
            <div style={{ width: '20vw', zIndex: 1 }}>
              {isStartCalendar ? (
                <div className='calendar-div'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                      <DemoItem>
                        <p onClick={() => setIsStartCalendar(false)}>Hide</p>
                        <DateCalendar value={startValue} onChange={(newValue) => { setStartValue(newValue); setIsStartCalendar(false) }} />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              ) : (
                <label className='calendar-label' onClick={() => setIsStartCalendar(true)}>
                  {/* Start date: */}
                  Дуусах хугацаа:
                  <p className='calendar'>{startValue.format('YYYY-MM-DD')}</p>
                </label>
              )}
            </div>
          </div>
          <div className={styles.column}>
            <label>
              Барааны үлдэгдлээр:
              <select name="" id="">
                <option value="">Сонгох</option>
              </select>
            </label>
          </div>
        </div>
        <br />
        {isLoading ? <div className="loader"></div> : <ReportShopTable
          orders={orders}
          endValue={endValue} startValue={startValue}
          finance={reports} user={userInfo._id}
          deposits={deposits}
          depositsuccess={depositsuccess}
        />}
      </div>
    </>
  )
}

export default ShopReport
