import axios from 'axios';
import React, { useState } from 'react'

const UserAddModal = ({ closeModal }) => {
    const [role, setRole] = useState('');
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');

    const userInfo = JSON.parse(window.localStorage.getItem('userId'));

    const addUser = () => {
        axios.post('https://oldapi.turbodelivery.mn/user/register', {
            name: name,
            phone: phone,
            email: username,
            role: role,
            password: password,
            user: userInfo._id,
        })
            .then(() => window.location.reload(false))
            .catch(() => console.log('object'));
    }
    return (
        <div className='blur_container'>
            <div className='modal'>
                <div className="two-line">
                    <h2>Хэрэглэгч нэмэх</h2>
                    <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                </div>
                <label>
                    Role:
                    <select onChange={(e) => setRole(e.target.value)} name="" id="">
                        <option value=""></option>
                        <option value="driver">Жолооч</option>
                        <option value="user">Харилцагч</option>
                        <option value="admin">Админ</option>
                        <option value="operator">Нярав</option>
                    </select>
                </label>
                <label>
                    Name:
                    <input type="text" onChange={(e) => setName(e.target.value)} name="" id="" />
                </label>
                <label>
                    Phone:
                    <input type="number" onChange={(e) => setPhone(e.target.value)} name="" id="" />
                </label>
                <label>
                    Email:
                    <input type="text" onChange={(e) => setUsername(e.target.value)} name="" id="" />
                </label>
                <label>
                    Password:
                    <input type="password" onChange={(e) => setPassword(e.target.value)} name="" id="" />
                </label>
                <div className='button_container'>
                    <button onClick={addUser} className='button-white' style={{ color: '#2c9961' }}>Хадгалах</button>
                    <button onClick={closeModal} className='button-white' style={{ color: 'red' }}>Хаах</button>
                </div>
            </div>
        </div>
    )
}

export default UserAddModal
