import axios from 'axios';
import React, { useState } from 'react'

const EditUserModal = ({ closeModal, user }) => {
  const [role, setRole] = useState(user.role);
  const [name, setName] = useState(user.name);
  const [username, setUsername] = useState(user.email);
  const [password, setPassword] = useState(user.password);
  const [phone, setPhone] = useState(user.phone);

  const addUser = () => {
    axios.put('https://oldapi.turbodelivery.mn/user/' + user._id, {
      name: name,
      phone: phone,
      email: username,
      role: role,
      password: password,
      // user: user._id, 
    })
      .then(() => window.location.reload(false))
      .catch(() => console.log('object'));
  }
  return (
    <div className='blur_container'>
      <div className='modal'>
        <div className="two-line">
          <h2>Хэрэглэгчийн мэдээлэл өөрчлөх</h2>
          <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
        </div>
        <label>
          Role:
          <select value={role} onChange={(e) => setRole(e.target.value)} name="" id="">
            <option value=""></option>
            <option value="driver">Жолооч</option>
            <option value="user">Харилцагч</option>
            <option value="admin">Админ</option>
            <option value="operator">Нярав</option>
          </select>
        </label>
        <label>
          Name:
          <input type="text"
            value={name}
            onChange={(e) => setName(e.target.value)} name="" id="" />
        </label>
        <label>
          Phone:
          <input value={phone} type="number" onChange={(e) => setPhone(e.target.value)} name="" id="" />
        </label>
        <label>
          Email:
          <input value={username} type="text" onChange={(e) => setUsername(e.target.value)} name="" id="" />
        </label>
        <label>
          Password:
          <input value={password} type="password" onChange={(e) => setPassword(e.target.value)} name="" id="" />
        </label>
        <div className='button_container'>
          <button onClick={addUser} className='button-white' style={{ color: '#2c9961' }}>Хадгалах</button>
          <button onClick={closeModal} className='button-white' style={{ color: 'red' }}>Хаах</button>
        </div>
      </div>
    </div>
  )
}

export default EditUserModal
