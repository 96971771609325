import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Pages/Login/Login';
import Sidebar from './Components/Sidebar/Sidebar';
import Dashboard from './Pages/Dashboard/Dashboard';
import Order from './Pages/Order/Order';
import Finance from './Pages/Finance/Finance';
import Container from './Pages/Container/Container';
import Items from './Pages/Directry/Items/Items';
import Category from './Pages/Directry/Category/Category';
import Shops from './Pages/Shops/Shops';
import Accounting from './Role/Accounting/Accounting';
import Driver from './Role/Driver/Driver';
import ContainerReport from './Pages/Report/ContainerReport/ContainerReport';
import ShopReport from './Pages/Report/ShopReport/ShopReport';
import Users from './Pages/Users/Users';
import Driver_Orders from './Pages/Driver/Driver_Orders/Driver_Orders';
import DriverHistory from './Pages/Driver/Driver_History/Driver_History';
import OrderFiltered from './Pages/Order/OrderFiltered';
import OrderItems from './Pages/Order/OrderItems';
import XlsxImportPage from './Pages/XlsxImport/XlxsImport';
import { useEffect, useState } from 'react';
import axios from 'axios';

function App() {
  const user = window.localStorage.getItem('userId');
  const [orders, setOrders] = useState([]);
  const userInfo = JSON.parse(window.localStorage.getItem('userId'));
  const [isLoading, setIsLoading] = useState(true);
  const [isNullOrder, setIsNullOrder] = useState(false);

  useEffect(() => {
    if (isLoading && userInfo) {
      // alert('s');
      axios.get('https://oldapi.turbodelivery.mn/order?sort=-createdAt&user=' + userInfo._id)
        .then((e) => { setOrders(e.data.data); setIsLoading(false) })
        .catch((e) => console.log(e));
    }
    if (userInfo === null && orders.length === 0) {
      // alert('a')
      setIsLoading(false)
    }

    if (userInfo !== null && orders.length === 0 && !isNullOrder) {
      setIsNullOrder(true);
      // alert('w')
      setIsLoading(true);
    }
  }, [isLoading, orders]);


  return (
    <>
      <Router>
        {user !== null ? <Sidebar /> : ''}
        {isLoading ? <div className="loader-container"><div className='loader'></div></div> :
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/dashboard' element={
              <>
                <Dashboard orders={orders} />
              </>
            } />
            <Route path='/order' element={<Order orders={orders} />} />
            <Route path='/orderdetial/:id' element={<OrderFiltered orders={orders} />} />
            <Route path='/finance' element={<Finance finance={orders} />} />
            <Route path='/container' element={<Container />} />
            <Route path='/items' element={<Items />} />
            <Route path='/category' element={<Category />} />
            <Route path='/shop' element={<Shops />} />
            <Route path='/accounting' element={<Accounting />} />
            <Route path='/driver' element={<Driver />} />
            <Route path='/reportcontainer' element={<ContainerReport orders={orders} />} />
            <Route path='/reportshop' element={<ShopReport orders={orders} />} />
            <Route path='/driverorder' element={<Driver_Orders />} />
            <Route path='/driverhistory' element={<DriverHistory />} />
            <Route path='/users' element={<Users />} />
            <Route path='/orderitems' element={<OrderItems orders={orders} />} />
            <Route path='/xlxs' element={<XlsxImportPage />} />
          </Routes>
        }
      </Router>
    </>
  );
}

export default App;
