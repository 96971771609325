import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import ContainerStatusChangeModal from '../Modal/EditModal/ContainerStatusChangeModal';
import TablePagination from '@mui/material/TablePagination'; // Энэ мөрөнд TablePagination компонентийг нэмнэ
import { LiaCommentsSolid } from "react-icons/lia";
import CommentModal from '../Modal/Uttils/CommentModal';


export default function ContainerTable({ containers, user, users, filterStatus, shop }) {
  const [isModal, setModal] = React.useState(false);
  const [id, setId] = React.useState('');
  const [reportId, setReportId] = React.useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0); // Энэ мөрөнд page-г нэмнэ
  const [comment, setComment] = React.useState('');
  const [commentModal, setCommentModal] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Энэ мөрөнд setPage функцыг дуудаж 0-р тогтоож өгнө
  };

  const closeModal = () => {
    setModal(false);
    setCommentModal(false);
  }

  return (
    <>
      {commentModal ? <CommentModal closeModal={closeModal} text={comment} /> : <></>}
      {isModal && id !== '' && reportId !== '' ? <ContainerStatusChangeModal id={id} reportId={reportId} closeModal={closeModal} /> : <></>}
      <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
        <Table sx={{ minWidth: 650, maxWidth: '88vw' }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: '#2c9961', color: '#fff' }}>
              <TableCell className='table-item' style={{ color: '#fff' }}><p className='table-title'>Id</p> </TableCell>
              <TableCell className='table-item' style={{ color: '#fff' }}> <p className='table-title'>Агуулах</p></TableCell>
              {/* <TableCell className='table-item' style={{ color: '#fff' }}> <p className='table-title'>Харилцагч</p></TableCell> */}
              <TableCell className='table-item' style={{ color: '#fff' }} align="center"><p className='table-title'>Дэлгүүр</p></TableCell>
              <TableCell className='table-item' style={{ color: '#fff' }} align="center"><p className='table-title'>Төлөв</p></TableCell>
              <TableCell className='table-item' style={{ color: '#fff' }} align="center"><p className='table-title'>Бараа</p></TableCell>
              <TableCell className='table-item' style={{ color: '#fff' }} align="center"><p className='table-title'>Тоо хэмжээ</p></TableCell>
              <TableCell className='table-item' style={{ color: '#fff' }} align="center"><p className='table-title'>Бүртгэсэн огноо</p></TableCell>
              <TableCell className='table-item' style={{ color: '#fff' }} align="center"><p className='table-title'>Үйлдэл</p></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {containers.map((row, index) =>
            (row.item
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .filter((element) => filterStatus ?
                element.status === filterStatus : element
              ).filter((el) => user ? el.user === user : el)
              .sort((a, b) => dayjs(b.date.toString()) - dayjs(a.date.toString()))
              .map((e, itemIndex) => (
                <TableRow className='hover'
                  key={row._id}
                // sx={{ '&:last-child td, &:last-child th': { border: 1 } }}
                // style={{border: '1px solid #000'}}
                >
                  <TableCell align="left">{1 + index + itemIndex}</TableCell>
                  <TableCell component="th" scope="row">
                    {/* {row.container} */}
                    Turbo delivery <br />
                    агуулах
                  </TableCell>
                  {/* <TableCell component="th" scope="row">
                    {users.filter((el) => el.id === row.user).map((el) => (
                      <p>{el.name}</p>
                    ))}
                  </TableCell> */}
                  <TableCell align="center">{
                    shop.find((e) => e.user === row.user)?.name
                  }</TableCell>
                  <TableCell align="center">
                    <div className='line-start'>
                      {e.comment ?
                        < LiaCommentsSolid
                          onClick={() => {
                            setComment(e.comment);
                            setCommentModal(true);
                          }}
                          cursor={'pointer'} size={20} color='red' /> : <></>}
                      <p className={e.status === 'Хүлээж авсан' ? 'green-border' :
                        e.status === 'Зарлага' ? 'red-border' : 'blue-border'}>
                        {e.status}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell align="center">{e.name}</TableCell>
                  <TableCell align="center">{e.staticUnit}</TableCell>
                  <TableCell align="center">{dayjs(row.createdAt).format('YYYY-MM-DD')}</TableCell>
                  <TableCell align="center">
                    {/* <div>
                      <CiEdit
                        onClick={() => { setModal(true); setReportId(e.reportId); setId(e._id) }}
                        size={30} style={{ cursor: 'pointer' }} color='blue'
                      />
                    </div> */}
                  </TableCell>
                </TableRow>
              )))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={containers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}