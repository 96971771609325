import React from 'react'
import styles from './Navbar.module.css'
import { IoIosLogOut } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

const Navbar = ({ title }) => {
  const user = JSON.parse(window.localStorage.getItem('userId'));
  const navigate = useNavigate();

  const log_out = () => {
    window.localStorage.removeItem('userId');
    navigate("/");
    window.location.reload(false);
  }


  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      <div className={styles.space_between}>
        <p style={{ color: '#838383' }}>{user.name}</p>
        <div onClick={log_out} className={styles.log_out}>
          <IoIosLogOut color='red' size={25} />
          <p>Гарах</p>
        </div>
      </div>
    </div>
  )
}

export default Navbar
