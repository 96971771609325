import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import TablePagination from '@mui/material/TablePagination'; // Энэ мөрөнд TablePagination компонентийг нэмнэ

export default function ShopsTable({ shops }) {
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0); // Энэ мөрөнд page-г нэмнэ
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Энэ мөрөнд setPage функцыг дуудаж 0-р тогтоож өгнө
  };

  return (
    <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
      <Table sx={{ minWidth: 650, maxWidth: '88vw' }} aria-label="simple table">
        <TableHead>
          <TableRow style={{ background: '#2c9961', color: '#fff' }}>
            <TableCell style={{ color: '#fff', fontSize: 16 }}>Id</TableCell>
            {/* <TableCell style={{ color: '#fff', fontSize: 16 }}>Харилцагч</TableCell> */}
            <TableCell style={{ color: '#fff', fontSize: 16 }}>Зураг</TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Дэлгүүрийн нэр</TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Утасны дугаар</TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Илгээсэн мессеж</TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Бүртгэсэн огноо</TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Үйлдэл</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shops
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <TableRow className='hover'
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">{index + 1}</TableCell>
                {/* <TableCell align="left">{users
                  .filter((e) => e._id.includes(row.user))
                  .map((e) => (
                    <p>{e.name}</p>
                  ))}
                </TableCell> */}
                <TableCell align="center">
                  {/* {row.name} */}
                </TableCell>
                <TableCell align="center" component="th" scope="row">{row.name}</TableCell>
                <TableCell align="center">{row.phone}</TableCell>
                <TableCell align="center">0</TableCell>
                <TableCell align="center">{dayjs(row.createdAt).format('YYYY-MM-DD')}</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={shops.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}